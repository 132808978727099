/* http://meyerweb.com/eric/tools/css/reset/
   v2.0b1 | 201101
   NOTE: WORK IN PROGRESS
   USE WITH CAUTION AND TEST WITH ABANDON */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

[role="button"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/* Reset `button` and button-style `input` default styles */
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  margin: 0;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none; }

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0px; }

[role="button"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  cursor: pointer; }
  [role="button"]:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  button:hover {
    outline: 0; }

/* Get rid of the "X" clear button on search fields */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

.main {
  padding: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: 100vh;
  width: 100%;
  background-color: #FFFFFF;
  color: #1E2731;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (min-width: 600px) {
    .main {
      padding: 80px; } }

.name {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 40px;
  color: #1E2731;
  font-size: 18px; }
  @media (min-width: 600px) {
    .name {
      margin-bottom: 60px; } }

.intro {
  font-family: 'Merriweather', serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 40px; }
  @media (min-width: 400px) and (max-width: 1399px) {
    .intro {
      font-size: -webkit-calc(20px + (60 - 20) * (100vw - 400px) / (1400 - 400));
      font-size: calc(20px + (60 - 20) * (100vw - 400px) / (1400 - 400)); } }
  @media (min-width: 1400px) {
    .intro {
      font-size: 60px; } }
  @media (min-width: 600px) {
    .intro {
      width: 80%;
      line-height: 1.3;
      margin-bottom: 60px; } }

.intro a {
  text-decoration: none;
  -webkit-box-shadow: 0 -0.12em 0 #8eaebd inset;
          box-shadow: 0 -0.12em 0 #8eaebd inset;
  -webkit-transition: color 0.2s, -webkit-box-shadow 0.2s;
  transition: color 0.2s, -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s, color 0.2s;
  transition: box-shadow 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
  color: #30415d; }
  .intro a:hover, .intro a:focus {
    -webkit-box-shadow: 0 -0.12em 0 #cf6766 inset;
            box-shadow: 0 -0.12em 0 #cf6766 inset;
    color: #1E2731; }

.email {
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }

.email a {
  text-decoration: none;
  -webkit-box-shadow: 0 2px 0 #30415d;
          box-shadow: 0 2px 0 #30415d;
  -webkit-transition: color 0.2s, -webkit-box-shadow 0.2s;
  transition: color 0.2s, -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s, color 0.2s;
  transition: box-shadow 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
  color: #8eaebd; }
  .email a:hover, .email a:focus {
    -webkit-box-shadow: 0 2px 0 #cf6766;
            box-shadow: 0 2px 0 #cf6766;
    color: #1E2731; }

/*# sourceMappingURL=main.css.map */